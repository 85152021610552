import request from '@/utils/service'

/**
 * 消息通知列表查询
 * @author peng-xiao-shuai
 */
export function noticeList(data) {
  return request({
    url: '/tower/notice/findPage',
    data
  })
}

/**
 * 消息通知列表删除
 * @author peng-xiao-shuai
 * @param {number} id
 */
export function noticeDelete(id) {
  return request({
    url: '/tower/notice/delete',
    data: { id }
  })
}

/**
 * 消息通知列表删除所有
 * @author peng-xiao-shuai
 */
export function noticeClearAll() {
  return request({
    url: '/tower/notice/clearAll'
  })
}

/**
 * 消息通知更新已读
 * @author peng-xiao-shuai
 */
export function noticeUpdateStatus(id) {
  return request({
    url: '/tower/notice/update',
    data: {
      status: 1,
      id
    }
  })
}

/**
 * 分页查询电塔
 * @author peng-xiao-shuai
 * @param {{ pageNo: number, pageSize: number, condition: string, orderBy: string, sort: 'asc' | 'desc'}} data
 */
export function findPage(data) {
  return request({
    url: '/tower/findPage',
    data
  })
}

/**
 * 电塔地址下拉查询
 * @author peng-xiao-shuai
 */
export function locationList() {
  return request({
    url: '/tower/location/list',
    method: 'get'
  })
}

/**
 * defectType列表下拉查询
 * @author peng-xiao-shuai
 */
export function getDefectTypeList() {
  return request({
    url: '/tower/alert/defectType/list',
    method: 'get'
  })
}

/**
 * 分页查询报警信息
 * @author peng-xiao-shuai
 * @param {{ pageNo: number, pageSize: number, severity: string, orderBy: string, sort: 'asc' | 'desc'}} data
 */
export function alertFindPage(data) {
  return request({
    url: '/tower/alert/findPage',
    data
  })
}

/**
 * 分页查询报警详情信息
 * @author peng-xiao-shuai
 * @param {{ id: string}} data
 */
export function alertFindDetail(id) {
  return request({
    url: '/tower/alert/detail',
    data: { id }
  })
}

/**
 * 报警信息下拉查询
 * @author peng-xiao-shuai
 */
export function alertTowerLocation() {
  return request({
    url: '/tower/alert/towerLocation/findList',
    method: 'get'
  })
}

/**
 * 更新报警详情信息
 * @author peng-xiao-shuai
 * @param {{ defectType: string, description: string, id: string, severity: string }} data
 */
export function updateAlert(data) {
  return request({
    url: '/tower/alert/updateAlert',
    data
  })
}

/**
 * 查询某图片下的所有缺陷
 * @author peng-xiao-shuai
 * @param {string} originImgS3Key
 */
export function findListByImg(originImgS3Key) {
  return request({
    url: '/tower/alert/findListByImg',
    params: {
      originImgS3Key
    }
  })
}
