<template>
  <div class="tower-nav _flex _flex-align__center _flex-justify__between">
    <div class="_flex _flex-center tower-nav__name">
      <i class="el-icon-s-unfold"></i>
      <span class="_font-weight__600 _font-size__sub__heading"
        >Tower - {{ $store.state.user.currentTowerCode }}</span
      >
    </div>

    <Transition name="fade-opacity">
      <slot>
        <div class="tower-nav__tabs _flex" v-show="$route.meta.tabs">
          <div
            v-for="(item, index) in tabs"
            :key="item.label"
            :class="[
              'tower-nav__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
              { active: index == tabsIndex }
            ]"
            @click="handleNavClick(item, index)"
          >
            {{ item.label }}
          </div>

          <!-- 红色线 -->
          <div class="tower-nav__tabs__border" :style="borderStyle"></div>
        </div>
      </slot>
    </Transition>

    <!-- <Transition name="fade-opacity"> -->
    <slot name="right">
      <KeepAlive>
        <Component :is="rightComponentName" />
      </KeepAlive>
    </slot>
    <!-- </Transition> -->
  </div>
</template>

<script>
import { ROUTER_NAME } from '@/router/index'
import TowerNavButton from '@/views/model/components/tower-nav-button'
import common from '@/common'

export default {
  components: {
    TowerNavButton
  },
  data() {
    return {
      ROUTER_NAME,
      towerCode: common.code,

      componentName: 'tilt-sensor',
      domPosition: [],
      tabsIndex: 0,
      tabs: [],

      trigger: ''
    }
  },
  computed: {
    borderStyle() {
      if (this.domPosition.length === 0) return { width: '0px' }
      const style = {
        width: `${this.domPosition[this.tabsIndex].width}px`,
        transform: `translateX(${this.domPosition[this.tabsIndex].left}px)`
      }

      return style
    },
    rightComponentName() {
      switch (this.$route.name) {
        case ROUTER_NAME.MODEL:
          return 'TowerNavButton'
        default:
          return 'div'
      }
    }
  },
  mounted() {
    // 收到加载完成事件，开始传递组件名称
    this.$bus.$on('mounted', () => {
      const val = this.$route.query
      if (val.tabsIndex) {
        this.tabsIndex = val.tabsIndex
        this.componentName = this.tabs[val.tabsIndex] && this.tabs[val.tabsIndex].componentName

        this.$bus.$emit('route-nav', this.tabs[val.tabsIndex], {
          source: 'layout-tower-nav',
          trigger: this.trigger
        })
      }
    })
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function (val) {
      if (val.tabsIndex) {
        this.trigger = common.TRIGGER_TYPE.ROUTER
        this.tabsIndex = val.tabsIndex
        this.componentName = this.tabs[val.tabsIndex] && this.tabs[val.tabsIndex].componentName
      }
    },
    '$route.meta': {
      handler(val) {
        if (val.tabs && val.tabs.length) {
          this.tabs = val.tabs
          this.$nextTick(() => {
            setTimeout(this.getDom, 300)
          })
        }
      },
      immediate: true
    },
    // 监听 tabsIndex 统一管理
    tabsIndex: {
      handler(val) {
        this.$bus.$emit('route-nav', this.tabs[val], {
          source: 'layout-tower-nav',
          trigger: this.trigger
        })
      },
      immediate: true
    }
  },
  methods: {
    getDom() {
      const doms = document.getElementsByClassName('tower-nav__tabs__item')
      for (let i = 0; i < doms.length; i += 1) {
        this.domPosition.push({
          width: doms[i].clientWidth,
          left: doms[i].offsetLeft
        })
      }
    },
    handleNavClick(item, index) {
      this.trigger = common.TRIGGER_TYPE.CLICK
      this.tabsIndex = index
      this.componentName = item.componentName
    }
  }
}
</script>

<style lang="scss" scoped>
.tower-nav {
  width: calc(100vw - var(--menu-width));
  height: var(--nav-height);
  padding: 0 40px;
  background: var(--color-white);
  z-index: 2;
  &__name {
    i {
      margin-right: 15px;
    }
  }

  &__tabs {
    position: relative;
    height: 35px;

    &__item {
      transition: all 0.3s;
      line-height: 35px;
      &.active {
        color: var(--color-primary);
      }
    }

    &__item + .tower-nav__tabs__item {
      margin-left: 40px;
    }

    &__border {
      position: absolute;
      transition: all 0.3s;
      bottom: 0;
      left: 0;
      height: 3px;
      background: var(--color-primary);
    }
  }
}
</style>
