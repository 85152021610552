var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-content"},_vm._l((_vm.mainRouterMap),function(item){return _c('div',{key:item.path,class:[
      'menu-content__item _flex _flex-center _pointer',
      { active: _vm.$route.meta.parentPath === item.meta.parentPath },
      { disabled: !_vm.disabledMenu(item.children[0].meta) }
    ],on:{"click":function($event){_vm.disabledMenu(item.children[0].meta) &&
        item.children[0].path !== _vm.$route.path &&
        _vm.$router.push(item.children[0].path)}}},[_c('div',{staticClass:"img-box _flex _flex-center"},[_c(item.meta.icon,{tag:"component"})],1),_c('div',{staticClass:"menu-content__item__label"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstCharacter(item.children[0].meta.label))+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }