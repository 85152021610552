<template>
  <div class="app-wrapper">
    <LayoutHeader />

    <div class="app-wrapper__main _flex">
      <LayoutMenu />

      <div class="app-wrapper__main__right">
        <Transition name="fade-opacity">
          <LayoutNav v-show="$route.meta.nav || $route.meta.tabs">
            <component v-if="$route.meta.component" :is="$route.meta.component" />
          </LayoutNav>
        </Transition>

        <div class="app-wrapper__main__content">
          <el-scrollbar
            :style="{
              height: `calc(100vh - var(--header-height) - ${
                $route.meta.nav || $route.meta.tabs ? 'var(--nav-height)' : '0px'
              })`
            }"
          >
            <Transition name="slate" mode="out-in">
              <keep-alive>
                <router-view></router-view>
              </keep-alive>
            </Transition>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { layoutNodeSize } from '@/common/size'
// eslint-disable-next-line import/no-unresolved, import/no-cycle
import { routerNames } from '@/router'
import LayoutHeader from './components/header'
import LayoutMenu from './components/menu'
import LayoutNav from './components/tower-nav'

export default {
  name: 'layout',
  components: {
    LayoutHeader,
    LayoutMenu,
    LayoutNav
  },
  data() {
    return {
      routerNames,
      layoutNodeSize
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &__main {
    &__content {
      width: calc(100vw - var(--menu-width));
      // padding: 30px 50px;
      // padding: 30px 0px;

      > ::v-deep .el-scrollbar {
        padding: var(--main-padding) 0;
        & > .el-scrollbar__wrap > .el-scrollbar__view {
          margin: 0 var(--main-margin);
          > div {
            width: var(--main-width);
            transition: all 0.2s;
          }
        }
      }
    }
  }
}
</style>
