import Vue from 'vue'
import Router from 'vue-router'
// eslint-disable-next-line import/no-unresolved
import Layout from '@/views/layout/index'
import common from '@/common'

Vue.use(Router)

// 定义常量
export const ROUTER_NAME = {
  HOME: 'home',
  VIEW_PDF: 'view-pdf',
  EDIT_PROFILE: 'edit-profile',
  DASHBOARD: 'dashboard',
  DATA: 'data',
  ANALYSIS: 'analysis',
  MODEL: 'model',
  SETTING: 'setting'
}

// 通用路由
export const constantRouterMap = [
  // eslint-disable-next-line import/no-unresolved
  { path: '/login', component: () => import('@/views/login/index') }
]

// 定义路由名称
export const routerNames = [
  [ROUTER_NAME.HOME, ROUTER_NAME.EDIT_PROFILE],
  [ROUTER_NAME.DASHBOARD, ROUTER_NAME.VIEW_PDF],
  ROUTER_NAME.DATA,
  ROUTER_NAME.ANALYSIS,
  ROUTER_NAME.MODEL,
  ROUTER_NAME.SETTING
]

// 定义不同的路由，不同的meta
const routeMeta = {
  [ROUTER_NAME.DASHBOARD]: {
    nav: true,
    towerCode: common.code
  },
  [ROUTER_NAME.DATA]: {
    tabs: [
      {
        label: 'Tilt Sensor',
        componentName: 'tilt-sensor'
      },
      {
        label: 'Ground Camera',
        componentName: 'ground-camera'
      },
      {
        label: 'Tower Camera',
        componentName: 'tower-camera'
      },
      {
        label: 'Documents',
        componentName: 'documents'
      }
    ],
    towerCode: common.code
  },
  [ROUTER_NAME.ANALYSIS]: {
    nav: true
  },
  [ROUTER_NAME.MODEL]: {
    nav: true,
    label: '3d Model',
    component: () => import('@/views/model/components/tower-nav-tabs'),
    towerCode: common.code
  },
  [ROUTER_NAME.SETTING]: {
    nav: true,
    towerCode: common.code
  }
}

// 生成路由
export const mainRouterMap = routerNames.map((item) => {
  const pathName = Array.isArray(item) ? item[0] : item
  // 创建子集路由
  const craetChildrenRoute = (name) => ({
    path: `/${name}`,
    name,
    meta: { label: name, ...routeMeta[pathName], parentPath: `/${pathName}` },
    component: () => import(`@/views/${pathName}/${name}.vue`)
  })

  const route = {
    path: pathName === ROUTER_NAME.HOME ? '/' : `/${pathName}-layout`,
    component: Layout,
    redirect: `/${pathName}`,
    meta: {
      name: pathName,
      parentPath: `/${pathName}`,
      icon: '',
      iconActive: ''
    },
    children: Array.isArray(item) ? item.map(craetChildrenRoute) : [craetChildrenRoute(item)]
  }

  // 添加图片
  // for (let i = 0; i < 2; i += 1) {
  import(`@/icons/svg/${pathName}.svg`).then((res) => {
    route.meta.icon = res.default
  })
  // }

  return route
})

export default new Router({
  routes: [...constantRouterMap, ...mainRouterMap],
  mode: 'history' // 去掉url中的#
})
