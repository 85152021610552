import nprogress from 'nprogress'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import router from './router'
import store from './store'

import 'nprogress/nprogress.css'

nprogress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
  nprogress.start()
  const isToLoginPage = to.path === '/login'
  // 没登录的情况下需要跳转到登录页面
  if (!Cookies.get('tower-token')) return isToLoginPage ? next() : next({ path: '/login' })

  // // 如果登录了还跳转到白名单则重定向回首页
  if (isToLoginPage && !Object.keys(to.query).length) return next({ path: '/home' })

  // 获取过权限则进入下个页面
  if (store.getters.userInfo && store.getters.userInfo.id) return next()

  store.dispatch('getIsOn')

  // 没获取过授权则获取用户信息
  store
    .dispatch('getUserInfo')
    .then(() => {})
    .catch(() => {
      store.dispatch('logout').then(() => {
        Message.error('获取权限失败，请重新登录')
        next({ path: '/login' })
      })
    })
  return next()
})

router.afterEach(() => {
  nprogress.done()
})
