// eslint-disable-next-line import/no-cycle
import { optionsFun } from '@/utils'

export default {
  // 没有数据显示时使用此代替
  EMPTY_TEXT: '--',

  // 动态塔ID
  code: localStorage.getItem('code'),

  // 通用常量合集
  constData: {
    severity: optionsFun({
      Critical: 'Critical',
      Moderate: 'Moderate',
      Minor: 'Minor'
    })
  },

  TRIGGER_TYPE: {
    ROUTER: 'router', // 路由触发
    CLICK: 'click', // 手动点击
    EVENT: 'event' // 事件触发
  }
}
