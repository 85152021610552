import request from '@/utils/service'

/**
 * 获取电塔配置
 * @author peng-xiao-shuai
 */
export function settingGet() {
  return request({
    url: '/tower/setting/get',
    method: 'get'
  })
}

/**
 * 修改电塔配置
 * @author peng-xiao-shuai
 * @param {{ groundCamera: number, tiltSensor: number, towerCamera: number }} data
 */
export function settingUpdate(data) {
  return request({
    url: '/tower/setting/update',
    data
  })
}

/**
 * 当前是否开启脱敏
 * @author peng-xiao-shuai
 */
export function privacyIsOn() {
  return request({
    url: '/privacy/isOn',
    method: 'get'
  })
}

/**
 * 设置是否开启脱敏
 * @author peng-xiao-shuai
 */
export function privacySetOnIsOn(isOn) {
  return request.post(`/privacy/setOn/${Number(isOn)}`)
}
