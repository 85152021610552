import axios from 'axios'
import Cookies from 'js-cookie'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/restApi' : process.env.VUE_APP_REQUEST_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'content-type': 'application/json'
  },
  timeout: 30000,
  method: 'post'
})

axios.defaults.withCredentials = true

service.interceptors.request.use(
  (config) => {
    config.method = config.method || 'post'
    config.data = JSON.parse(JSON.stringify(config.data || '{}'))
    const token = Cookies.get('tower-token')
    if (token) {
      config.headers.Authorization = token
    }

    if (config.data.current) {
      config.data.pageNo = config.data.current
      delete config.data.current
    }

    if (config.data.limit) {
      config.data.pageSize = config.data.limit
      delete config.data.limit
    }

    return config
  },
  (error) => Promise.reject(error)
)

service.interceptors.response.use(
  (res) => res.data,
  (error) => {
    console.log(error)
    const errorMessage =
      error.response.status === 500 ? 'uncatch error' : error.response.data.message
    if (error.response.status === 401) {
      Message.error('用户未授权')

      localStorage.login = false
      window.location.reload()
    }

    Message.error(errorMessage)
    return Promise.reject(error)
  }
)

export default service
