var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{staticStyle:{"padding":"0"},attrs:{"placement":"bottom","width":"400","trigger":"click","popper-class":"popover-padding0"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"user-info _font-weight__600 info-color"},[_c('div',{staticClass:"user-info__header _flex _flex-justify__between"},[_c('span',{staticClass:"_font-weight__600"},[_vm._v("My Profile")]),_c('i',{staticClass:"el-icon-close _pointer",on:{"click":function($event){_vm.visible = false}}})]),_c('div',{staticClass:"user-info__detail"},[_c('div',{staticClass:"user-info__detail__item"},[_c('span',{staticClass:"_font-weight__400"},[_vm._v("Name")]),_c('div',[_vm._v(_vm._s(_vm.userInfo.nickName))])]),_c('div',{staticClass:"user-info__detail__item"},[_c('span',{staticClass:"_font-weight__400"},[_vm._v("Email")]),_c('div',{staticClass:"_flex _flex-justify__between"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.email))]),_c('span',{staticClass:"primary-color _pointer",on:{"click":function($event){return _vm.$router.push({
                path: '/login',
                query: {
                  type: _vm.update_type
                }
              })}}},[_vm._v("Change Password")])])]),_c('div',{staticClass:"user-info__detail__item"},[_c('span',{staticClass:"_font-weight__400"},[_vm._v("Company Name")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.userInfo.companyName))])])]),_c('div',{staticClass:"operate _flex _flex-align__center _flex-justify__between"},[_c('span',{staticClass:"_pointer",on:{"click":_vm.handleSignOut}},[_vm._v("Sign Out")]),_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":function($event){_vm.visible = false
            _vm.$router.push({
              path: 'edit-profile'
            })}}},[_vm._v("Edit Profile")])],1)])]),_c('span',{staticClass:"header-content__tools__user__text _pointer _font-weight__600",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.userInfo.nickName)+" "),_c('i',{class:['el-icon-caret-top', _vm.visible ? 'arrow-down' : 'arrow-up']})])])
}
var staticRenderFns = []

export { render, staticRenderFns }